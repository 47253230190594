import './App.css';
import {ChoiceGroup, FontSizes, FontWeights, Slider} from '@fluentui/react';
import { getTheme } from '@fluentui/react';
import { useEffect, useState } from 'react';

function App() {

  const theme = getTheme();

  const [people, setPeople] = useState(5);
  const [documents, setdocuments] = useState(5);
  const [multipleSingees, setMultipleSignees] = useState(true);
  const [print, setPrint] = useState(true);
  const [scanned, setScanned] = useState(true);
  const [priority, setPriority] = useState(false);

  const [currentCosts, setCurrentCosts] = useState(0);
  const [costTegoly, setCostsTegoly] = useState(0);
  const [timeAll, setTimeAll] = useState(0);
  const [savings, setSavings] = useState(0);

  useEffect(() => {
    var allDocuments = people * documents;
    var wagesPerHour = 30;
    var time = 15;
    var addCosts = 0;
    var paperCost = 0;
    if(print){
      time += 3;
      paperCost += 0.1 * allDocuments;
    } 
    if(scanned){
      time += 2;
      paperCost += 0.05 * allDocuments;
    } 
    //time += 3;
    if(!priority){
      addCosts += 1.6 * allDocuments;
    } else {
      addCosts += 11 * allDocuments;
    }
    if(multipleSingees) time += time * 0.4;
    var wagesCosts = (wagesPerHour / 60) * time;
    var sum = wagesCosts * allDocuments;
    sum += paperCost * 12;
    sum += addCosts;
    setCurrentCosts(sum * 12);
    setCostsTegoly(people * 360);
    setTimeAll((time * allDocuments * 12) / 60);
  }, [people, documents, multipleSingees, print, scanned, priority])

  useEffect(() => {
    setSavings(currentCosts - costTegoly);
  }, [currentCosts, costTegoly])
  return (
    <div className="App">
      <div style={{display:"flex", flexWrap:"wrap", justifyContent:"space-evenly"}}>
        <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", boxShadow:theme.effects.elevation8, gap:"30px", padding:"10px", backgroundColor:"#f1f4f9"}}>
          <Slider label='Wie viele Personen in Ihrem Unternehmen holen Unterschriften ein?' min={1} max={50} defaultValue={people} onChange={(num) => setPeople(num)} />

          <Slider label='Wie viele Dokumente schickt jeder Mitarbeiter zur Unterschrift (im Durchschnitt pro Monat)?' min={1} max={100} defaultValue={documents} onChange={(num) => setdocuments(num)}/>

          <ChoiceGroup label='Haben Sie mehrere Unterzeichner für ein Dokument?' defaultSelectedKey={"A"} options={[{key:"A", text:"Ja"}, {key:"B", text:"Nein"}]} 
            onChange={(e, o) => {o.key === "A" ? setMultipleSignees(true) : setMultipleSignees(false)}} />

          <ChoiceGroup label='Drucken Sie Ihre Dokumente?' defaultSelectedKey={"C"} options={[{key:"C", text:"Ja"}, {key:"D", text:"Nein"}]} 
            onChange={(e, o) => {o.key === "C" ? setPrint(true) : setPrint(false)}}/>

          <ChoiceGroup label='Scannen Sie Ihre Dokumente?' defaultSelectedKey={"E"} options={[{key:"E", text:"Ja"}, {key:"F", text:"Nein"}]} 
            onChange={(e, o) => {o.key === "E" ? setScanned(true) : setScanned(false)}}/>
          
          <ChoiceGroup label='Wie versenden Sie Ihre Dokumente per Post?' defaultSelectedKey={"I"} options={[{key:"I", text:"Großbrief"}, {key:"J", text:"Großbrief Express"}]} 
            onChange={(e, o) => {o.key === "I" ? setPriority(false) : setPriority(true)}}/>
        </div>

        <div style={{display:"flex", flexDirection:"column", boxShadow:theme.effects.elevation8}}>
          <img src='tegoly-01.png' style={{maxWidth:"500px"}} alt='tegoly'/>
          <div style={{flexGrow:1}}></div>
          <div style={{display:"flex", flexDirection:"column", gap:"20px", padding:"10px", backgroundColor:"#f1f4f9"}}>
            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.semibold}}>
              <div>Kosten pro Jahr:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(currentCosts)}</div>
            </div>
            <div style={{fontSize:FontSizes.medium}}>(Druck-, Scan-, Porto-, Arbeits- und Lohnnebenkosten)</div>
            <div style={{display:"flex", fontSize: FontSizes.large, fontWeight: FontWeights.semibold}}>
              <div>Kosten tegolySIGN Enterprise:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(costTegoly)}</div>
            </div>
            <div style={{display:"flex", fontSize: FontSizes.size24, fontWeight: FontWeights.semibold}}>
              <div>Zeitersparnis mit tegolySIGN: </div>
              <div style={{flexGrow:1}}></div>
              <div> {timeAll.toFixed(0)} Stunden</div>
            </div>
            <div style={{display:"flex", fontSize: FontSizes.size24, fontWeight: FontWeights.bold, color:"#316bac"}}>
              <div>Ersparnis mit tegolySIGN:</div>
              <div style={{flexGrow:1}}></div>
              <div>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(savings)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
